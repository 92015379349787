var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex-col section_12"},[_c('div',{staticClass:"justify-between group_30"},[_c('img',{staticClass:"image_22",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316208115921.png"}}),_c('div',{staticClass:"flex-row group_31"},[_c('span',{staticClass:"text_36",staticStyle:{"font-family":"Albbr"},on:{"click":function($event){return _vm.navPath('/drive')}}},[_vm._v("预约试驾")]),_vm._m(0)])]),_c('div',{staticClass:"flex-col group_32"},[_c('div',{staticClass:"justify-between"},[_vm._m(1),_c('img',{staticClass:"image_24",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316215117664.png"},on:{"click":_vm.goBack}})]),_vm._m(2),_c('div',{staticClass:"justify-between group_36"},[_vm._m(3),_c('div',{staticClass:"flex-row group_38"},[(_vm.isshowwx1)?_c('div',{staticClass:"wx-content1"},[_c('img',{staticClass:"images",attrs:{"src":require("../../assets/images/ewm.jpg")}})]):_vm._e(),_c('img',{staticClass:"image_6",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016330808267248.png"},on:{"mouseleave":_vm.wxout1,"mousemove":_vm.wxin1}}),(_vm.isshowwx)?_c('div',{staticClass:"wx-content2"},[_c('img',{staticClass:"images",attrs:{"src":require("../../assets/images/wx.png")}})]):_vm._e(),_c('img',{staticClass:"image_26",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016330762903778.png"},on:{"mouseleave":_vm.wxout,"mousemove":_vm.wxin}}),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"http://zydadmin.kymco.life/index.php/admin/login/index.html"}},[_c('span',{staticClass:"text_38",staticStyle:{"font-family":"Albbr","cursor":"pointer"}},[_vm._v("工作后台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col group_34"},[_c('img',{staticClass:"image_23",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316223612676.png"}}),_c('div',{staticClass:"group_35"},[_c('div',{staticClass:"text_39"},[_vm._v("营销电话: 0519-68197852")]),_c('div',{staticClass:"text_40"},[_vm._v("服务热线: 400-808-0395")]),_c('div',{staticClass:"text_41"},[_vm._v("企业邮箱: ev@mail.kymco.com.cn")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text_42"},[_c('a',{staticClass:"text_42",attrs:{"href":"https://kymco.com.cn/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("光阳摩托")]),_vm._v(" / "),_c('a',{staticClass:"text_42",attrs:{"href":"https://www.ionex.com.tw/#view1","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" ionex台湾")]),_vm._v(" / "),_c('a',{staticClass:"text_42",attrs:{"href":"https://www.kymco.com.tw/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("光陽臺灣")]),_vm._v(" / "),_c('a',{staticClass:"text_42",attrs:{"href":"https://www.kymco.com/international","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("KYMCO Global")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group_37"},[_c('span',{staticClass:"text_43"},[_vm._v("Copyright © 2022 杭州英捷鑫科技有限公司  All rights reserved")]),_c('span',{staticClass:"text_44"},[_vm._v(".")]),_c('span',{staticClass:"text_45"},[_vm._v("  浙ICP备20029190号")]),_c('br'),_c('br'),_c('span',{staticClass:"text_46"},[_vm._v("Design by")]),_c('a',{attrs:{"href":"http://www.hansn.cn/"}},[_c('span',{staticClass:"text_47"},[_vm._v(" HANSN")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://weibo.com/u/7697284960","target":"_blank"}},[_c('img',{staticClass:"image_27",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316224251993.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.douyin.com/user/MS4wLjABAAAAdWMSrkFIpLbCu7HmLrcj8p4bIWQN_jZLgqdWiydzJyUL7qELp9G-ZmsxtvfikkuL","target":"_blank"}},[_c('img',{staticClass:"image_28",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316223373224.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.xiaohongshu.com/user/profile/55f6818962a60c265cdd1599?xhsshare=CopyLink&appuid=6117d683000000000101db7c&apptime=1669014013","target":"_blank"}},[_c('img',{staticClass:"image_6 image_29",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316221464315.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://kymco.tmall.com/?spm=a220o.1000855.1997427721.d4918089.4bcc5032n22gkh","target":"_blank"}},[_c('img',{staticClass:"image_30",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316224204316.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://mall.jd.com/index-12058581.html","target":"_blank"}},[_c('img',{staticClass:"image_6 image_31",attrs:{"src":"https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316226406954.png"}})])
}]

export { render, staticRenderFns }