<template>
  <div class="justify-between section">
      <img
      @click="navPath('/')"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fc4d37689f2800114f3626/16607020431581841539.png"
        class="image" />
      <div class="flex-row group_1">
        <span class="text" style="font-family: Albbr;">探索车型</span>
        <span class="text_1" @click="navPath('/ionex')">车能网</span>
        <span class="text_2" @click="navPath('/rent')">租电业务</span>
        <span  @click="navPath('/brand')" class="text_3 url">品牌</span>
        <span @click="navPath('/news')" class="text_3 url">动态</span>
        <span class="text_5" @click="navUrl('https://kymco.life/contact.html')">联系</span>
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fc4d37689f2800114f3626/16607020431727812191.png"
          class="image_1" />
      </div>
     
    </div>
</template>

<script>
export default {
  props:["show"],
  components: {},
  watch:{
   
  },
  data() {
    return {
      shows:true,
      list:[
      {title:"电动顶级仿赛街跑F9",
        zc:"   ",
        src:require('@/assets/images/header/f9.png'),
        url:""
      },
      {title:"新世代电轻摩i-OneX",
        zc:" 租电最高立减4400",
        src:require('@/assets/images/header/x.png'),
        url:"/ionex"
      },
      {title:"复古智能电摩i-Like",
        zc:"  租电立减4400",
        src:require('@/assets/images/header/like.png'),
        url:"/ilikes"
      },
      {title:"赛道级电摩S7",
        zc:"   租电立减11000",
        src:require('@/assets/images/header/s7.png'),
        url:"/s7"
      },
     {title:"新世代电轻摩i-OneXS  ",
        zc:"  租电最高立减4400",
        src:require('@/assets/images/header/xs.png'),
        url:""
      }
      ]
    };
  },
   mounted() {
    this.$nextTick(() => {
      var dom = $('#owl-demo')
      var sliderApp = $('#owl-demo').owlCarousel({
        center: false,
        items: 1,
        loop: true,
        margin: 0,
        autoWidth: true,
        // nav: false,
        responsive: {},
        smartSpeed: 500,
        dots: true,
        // navText: ['<i class="icon iconfont icon-back"></i>', '<i class="iconfont icon-more"></i>']

      });
      let dotDom = dom.find('.owl-dots');
      let dots = dotDom.find('.owl-dot');
      let numWrapper = '<div class="_dotNumberWrapper">';
      numWrapper += '<div class="_dotsWrapper">'
      dots.each((i, v) => {
        numWrapper += '<div class="_dot">' + getTwoNumber(i + 1) + '</div>'
      });
      numWrapper += '</div>';
      numWrapper += "<div class='_all'>" + getTwoNumber(dots.length + 1) + "</div>"
      numWrapper += '</div>';
      numWrapper = $(numWrapper).appendTo(dom.find('.content_list'));

      numWrapper.find('._dot').eq(0).addClass('active');
      
      dom.find('.owl-stage .owl-item.active').eq(1).find('.group_4 ').addClass('actives');  
      sliderApp.on('changed.owl.carousel', function (event) {
           
        var item = dom.find('.owl-dots .owl-dot.active').index();
        dom.find('.owl-stage .owl-item.active').removeClass('actives')
        dom.find('.owl-stage .owl-item .group_4').removeClass('actives')
       
        var num = event.page.index+=1;
        if(num>4){
          num=0;
        }
        $(`.a${num}`).addClass('actives'); 
        if ((item + 1) % 2 == 0) {
          $(dom).addClass('even');
         
        } else {
          
          $(dom).removeClass('even');
        }
       
       
       
        numWrapper.find('._dot').removeClass('active');
        numWrapper.find('._dot').eq(item).addClass('active');
      });



      function getTwoNumber(num) {
        return num < 10 ? '0' + num : num + '';
      };
      
    })
    
  },
  methods: {
    navPathdfsd(index){
      console.log(index)
      this.$router.push({ path:index });
    }
  },
};
</script>
<style>
  .mlist .owl-nav{
  display: none;
}
</style>
<style lang="scss" scoped>

.owl-carousel .owl-item .group_4{
  opacity: 0.5;
}
.owl-stage .group_4.actives {
  opacity: 1;
}

.owl-stage>.actives .text_7{
  color: #007aff;
}
.owl-stage  .group_4.actives {
  opacity: 1;
}

.owl-stage .group_4.actives .text_7{
  color: #007aff;
}

</style>
<style scoped lang="scss">
.section {
  padding: 20px 100px 19px;
  background-color: #ffffff;
 
  position: relative;
}



.section_1 {
  padding: 66px 0 95px;
  background-color: #fff;
}

.image {
  width: 55px;
  height: 30px;
}

.group_1 {
  margin: 0;
}

.image_2 {
  width: 6px;
  height: 6px;
  position: absolute;
  right: 918px;
  bottom: 11px;
}

.group_2 {
  padding: 0 149px;
  
}

.group_3 {
  margin-top: 34px;
  align-self: center;
  width: 1459px;
  position: relative;
}

.group_7 {
  margin-top: 81px;
  align-self: center;
  width: 1208px;
}

.text {
 
  font-size: 20px;
  font-family: 'Albbr';
 
}
.active{
   
}
.group_1>span:hover{
  position: relative;
  color: #1373f1;
  cursor: pointer ;
}
.group_1>span:hover::after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1373f1;
}

.text_1 {
  margin-left: 79px;
  
  color: #000;
  font-size: 20px;
  font-family: 'Albbr';
  
}

.text_2 {
  margin-left: 72px;
 
  color: #000;
  font-size: 20px;
  font-family: 'Albbr';
 
}

.text_3 {
  margin-left: 72px;
  color: #000;
  font-size: 20px;
  font-family: 'Albbr';

}

.text_4 {
  margin-left: 78px;

  color: #000;
  font-size: 20px;
  font-family: 'Albbr';
 
}

.text_5 {
  margin-left: 74px;
  color: #000;
  font-size: 20px;
  font-family: 'Albbr';
 
}

.image_1 {
  margin-left: 79px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
}

.image_3 {
  width: 438px;
  height: 313px;
}

.image_4 {
  margin-top: 20px;
  width: 429px;
  height: 293px;
}

.image_5 {
  margin-bottom: 3px;
  width: 434px;
  height: 310px;
}

.group_4 {
  flex-wrap: wrap;
  width: 33.333vw;
  justify-content: center;

  img {
    display: block;
  }
  div{
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  span {
    display: inline-block;
   
    text-align: center;
  }
}

.group_5 {
  line-height: 16px;
}

.group_6 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.group_8 {
  padding: 16px 0;
  position: relative;
}

.group_10 {
  margin-top: 57px;
  align-self: center;
}

.group_11 {
  margin-top: 5px;
  align-self: center;
  width: 181px;
  height: 3px;
  border-left: solid 76px #007aff;
  border-right: solid 76px #000000;
}

.text_6 {
  color: #000;
  font-size: 16px;
  
  line-height: 16px;
}

.text_7 {
  color: #000;
  font-size: 16px;
  
  line-height: 16px;
}

.text_8 {
  color: #000;
  font-size: 16px;
  
  line-height: 16px;
}

.text_9 {
  color: #007aff;
  font-size: 16px;
  
  line-height: 16px;
}

.text_10 {
  color: #000;
  font-size: 16px;
  
  line-height: 16px;
}

.text_11 {
  color: #000;
  font-size: 16px;
  
  line-height: 16px;
}

.image_6 {
  width: 8px;
  height: 8px;
}

.group_9 {
  padding: 19px 0 20px;
  width: 1200px;
  position: absolute;
  left: 4px;
  right: 4px;
  top: 0;
}

.text_12 {
  color: #000;
  font-size: 20px;
  
  line-height: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;

  &:hover{
    color: #007aff;
    border-bottom: 1px solid #007aff;
  }
}

.text_13 {
  margin-left: 24px;
  color: #000;
  font-size: 20px;
  
  line-height: 19px;
  border-bottom: 1px solid #000;

  &:hover{
    color: #007aff;
    border-bottom: 1px solid #007aff;
  }
}

.divider {
  background-color: #000000;
  width: 1200px;
  height: 1px;
}

.image_9 {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>