<template>      
  <div class="flex-col group_28">
  <div class="flex-col section_10">
    <img
      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005955488633086.png"
      class="image_21"
    />
    <!-- <div class="flex-row justify-between group_20"> -->
      <!-- <span class="text_24" @click="navPath('/yy')">预约试驾</span> -->
      <!-- <span class="text_25">经销商专区</span> -->
      <!-- <span class="text_26">工作后台</span> -->
    <!-- </div> -->
    <div class="flex-row justify-center group_21">
      <div class="flex-col items-center image-wrapper">
          <a href="https://mp.weixin.qq.com/s/LZn-0RKzXDbeh4DEnFWk0w">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005955501535848.png"
              class="image_22" 
            />
          </a>
      </div>
      <div class="flex-col items-center image-wrapper_1">
        <a href="https://weibo.com/u/7697284960">
          <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005955508533477.png"
          class="image_23"
        />
        </a>
      </div>
      <div class="flex-col items-center image-wrapper_2">
        <a href="https://www.douyin.com/user/MS4wLjABAAAAdWMSrkFIpLbCu7HmLrcj8p4bIWQN_jZLgqdWiydzJyUL7qELp9G-ZmsxtvfikkuL">
          <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005955508863001.png"
          class="image_24"
        />
        </a>
      </div>
    </div>
    <div class="flex-row justify-between group_22">
      <a href="https://www.xiaohongshu.com/user/profile/55f6818962a60c265cdd1599?xhsshare=CopyLink&appuid=6117d683000000000101db7c&apptime=1669014013">
        <img
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005955502555400.png"
        class="image_25"
      />
      </a>
      <a href="https://kymco.tmall.com/?spm=a220o.1000855.1997427721.d4918089.4bcc5032n22gkh">
        <img
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005955493909808.png"
        class="image_27"
      />
      </a>
      <a href="https://mall.jd.com/index-12058581.html">
        <img
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005955499720341.png"
        class="image_25 image_28"
        />
      </a>
    </div>
    <div class="section_11"><!----></div>
    <div class="flex-col space-y-25 group_23">
      <img
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005955498677267.png"
        class="image_29"
      />
      <div class="group_24">
        <span class="text_27">
          营销电话: 0519-68197852
         <br>
        </span>
        <div class="text_28">
          服务热线: 400-808-0395
          <br />
        </div>
        <span class="text_29">企业邮箱: ev@mail.kymco.com.cn</span>
      </div>
    </div>
    <span class="text_30">光阳摩托 / ionex台湾 / KYMCO Global</span>
    <div class="group_25">
      <span class="text_31">
        Copyright © 2022 杭州英捷鑫科技有限公司
        <br />
      </span>
      <span class="text_32">
        All rights reserved
        <br />
      </span>
      <span class="text_33">浙ICP备20029190号 Design by</span>
      <a href="http://www.hansn.cn/">
        <span class="text_34 text_35">&nbspHANSN</span>
      </a>
      <span class="text_34 text_36">BRAND</span>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        isshowwx:false
      };
    },
    methods: {
      wxn(){
        this.isshowwx=true
      }
    },
  };
</script>

<style scoped lang="css">

  .group_28 {
    position: relative;
    z-index: 55;
  /* padding: 30px 0 0 0; */
}
.section_10 {
  padding: 100px 72px 134px 79px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005944648852094.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.image_21 {
  align-self: center;
  width: 350px;
  height: 57px;
}
.group_20 {
  margin-top: 49px;
  padding-top: 31px;
  border-top: solid 1px #ffffff;
}
.text_24 {
  color: #ffffff;
  font-size: 28px;
  
  font-weight: 700;
  line-height: 27px;
}
.text_25 {
  color: #ffffff;
  font-size: 28px;
  
  font-weight: 700;
  line-height: 27px;
}
.text_26 {
  color: #ffffff;
  font-size: 28px;
  
  font-weight: 700;
  line-height: 26px;
}
.group_21 {
  margin-top: 84px;
  padding: 2px 44px 3px;
  position: relative;
}
.image-wrapper {
  position: absolute;
  left: 44px;
  top: 50%;
  transform: translateY(-50%);
  width: 94px;
}
.image_22 {
  width: 91px;
  height: 76px;
}
.image-wrapper_1 {
  width: 94px;
}
.image_23 {
  width: 94px;
  height: 77px;
}
.image-wrapper_2 {
  position: absolute;
  right: 49px;
  top: 50%;
  transform: translateY(-50%);
  width: 94px;
}
.image_24 {
  width: 68px;
  height: 82px;
}
.group_22 {
  margin-top: 71px;
  padding: 0 51px;
}
.image_25 {
  width: 82px;
  height: 82px;
}
.image_27 {
  width: 89px;
  height: 82px;
}
.image_28 {
  margin-right: 6px;
}
.section_11 {
  margin-right: 8px;
  margin-top: 86px;
  background-color: #ffffff;
  height: 1px;
}
.group_23 {
  margin-top: 88px;
  align-self: flex-start;
  width: 429px;
}
.image_29 {
  margin-right: 13px;
  width: 416px;
  height: 35px;
}
.group_24 {
  line-height: 36px;
  margin-top:30px;
  font-family: 'Albbl';
}
.text_27 {
  color: #ffffff;
  font-size: 28px;
  
  font-weight: 300;
  line-height: 36px;
}
.text_28 {
  color: #ffffff;
  font-size: 28px;
  
  font-weight: 300;
  line-height: 36px;
}
.text_29 {
  color: #ffffff;
  font-size: 28px;
  
  font-weight: 300;
  line-height: 36px;
}
.text_30 {
  margin-top: 75px;
  align-self: flex-start;
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 28px;
}
.group_25 {
  margin-top: 76px;
  align-self: flex-start;
  line-height: 30px;
}
.text_31 {
  color: #ffffff;
  font-size: 20px;
  
  line-height: 30px;
}
.text_32 {
  color: #ffffff;
  font-size: 20px;
  
  line-height: 30px;
}
.text_33 {
  color: #ffffff;
  font-size: 20px;
  
  line-height: 30px;
}
.text_34 {
  word-break: break-all;
}
.text_35 {
  color: #ffffff;
  font-size: 20px;
  
  font-weight: 700;
  line-height: 30px;
}
.text_36 {
  color: #ffffff;
  font-size: 20px;
  
  line-height: 30px;
}
.wx-content2 {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #fff;
  top: -120px;
  left: 50px;
}
.wx-content2::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
  border: 10px solid transparent;

  border-top: 10px solid #fff;
}
.images{
  width: 100%;
  height: 100%;
  padding: 5px;
}
</style>