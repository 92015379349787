<template>
  <div id="app">
    <router-view :key="$route.fullpath" />
   <play v-if="show" :url="url"></play>  


  </div>
</template>
<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import play from "./components/play.vue"
export default {
  data() {
    return {
      show:false,
      url:""
    };
  },
  components:{play},
  mounted() {
    this.$bus.$on('playvideo',(data)=>{
        
        this.show=data.show;
        this.url=data.url;
      })
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    
  },
};
</script>

<style>
/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/
h1{
  line-height: 1;
  margin: 0 ;
}
.slide-container{
     height: 100vh !important;
   }
.a {
  cursor: pointer;
}
input,
textarea {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
}
.nomal-block {
  width: 1197px;
  margin: 0 auto;
}
html {
  font-size: 10PX !important  ;
}
@media screen and (max-width: 1690px) {
  html {
    font-size: 7PX !important;
  }
}
@media screen and (max-width: 1500px) {
  html {
    font-size: 6PX !important;
  }
}
@media screen and (max-width: 1200px) {
  html {
    font-size: 6PX !important;
  }
}
@media screen and (max-width: 1100px) {
  html {
    font-size: 6PX !important;
  }
}
@media screen and (max-width: 1000px) {
  html {
    font-size: 1.3333333333vw !important;
  }
}
@media screen and (max-width: 850px) {
 
  
}

body {
  margin: 0;
  font-family: "微软雅黑","PingFang SC","Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
ul,li{
  list-style: none;
  padding: 0;
  margin: 0;
}
* {
  box-sizing: border-box;
  flex-shrink: 0;
}

#app {
  min-height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.items-start {
  display: flex;
  align-items: flex-start;
}

.items-center {
  display: flex;
  align-items: center;
}

.items-end {
  display: flex;
  align-items: flex-end;
}
.cur{
  cursor: pointer;
}
</style>
