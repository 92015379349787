<template>
  <div v-show="!show" :style="{opacity:!show?'1':'0',zIndex:show?'-1':'1'}" style="position: relative;"
    class="flex-col">
    <headersmall/> 
    <div class="flex-col space-y-82 sections">
      <div class="flex-col space-y-15 group">      
        <div class="flex-row justify-between" style="padding:0 120px">
          <div @click="navPath('/i-onex')" style="cursor: pointer;">
            <div>
              <img class="image"
                src="../../assets/images/ts-1.png" />
            </div>
            <!-- <div class="group_2"> -->
              <!-- <span class="font_1">i-OneX</span> -->
              <!-- <span class="font_2">租电最高立减4400</span> -->
            <!-- </div> -->
          </div>
          
          <div @click="navPath('/html/2')" style="cursor: pointer;">
            <div>
              <img class="image"
                src="../../assets/images/ts-2.png" />
            </div>
            <!-- <div class="group_2 "> -->
            <!-- <span class="font_1">i-OneXS</span> -->
            <!-- <span class="font_2">租电最高立减4400</span> -->
          <!-- </div> -->
          </div>

          
          <div  @click="navPath('/i-like')" style="cursor: pointer;">
            <div>
              <img class="image"
                src="../../assets/images/ts-3.png" />
            </div>
            <!-- <div class="group_2 "> -->
            <!-- <span class="font_1">i-Like</span> -->
            <!-- <span class="font_2">租电立减4400</span> -->
          <!-- </div> -->
          </div>
          
          <div @click="navPath('/s7')" style="cursor: pointer;">
            <div>
              <img class="image"
                src="../../assets/images/ts-4.png" />
            </div>
            <!-- <div class="group_2 "> -->
            <!-- <span class="font_1">S7</span> -->
            <!-- <span class="font_1 text">租电立减11000</span> -->
          <!-- </div> -->
          </div>

          <div @click="navPath('/html/1')" style="cursor: pointer;">
            <div>
              <img class="image"
                src="../../assets/images/ts-5.png" />
            </div>
            <!-- <div class="group_2 "> -->
              <!-- <span class="font_1 text_2">F9</span> -->
          <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="flex-col group_3" >
        <div class="flex-row space-x-25 group_4">
          <div>
            <span class="font_3 text_3"  @click="navPath('/rent')">租电业务</span>
             <div style="width:100%;height:2px;background-color:#007aff;margin-top: 2px;"></div>
          </div>
          <div>
            <span class="font_3 text_4" @click="navPath('/drive')">预约试驾</span>
            <div style="width:100%;height:2px;background-color:black;margin-top: 2px;"></div>
          </div>
        </div>
        <!-- <div class="divider"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headersmall from "./header.vue"
export default {
  props: ["show"],
  components: {headersmall},
  watch: {

  },
  data() {
    return {
      shows: true,
      list: [
        {
          title: "电动顶级仿赛街跑F9",
          zc: "   ",
          src: require('@/assets/images/header/f9.png'),
          url: ""
        },
        {
          title: "新世代电轻摩i-OneX",
          zc: " 租电最高立减4400",
          src: require('@/assets/images/header/x.png'),
          url: "/ionex"
        },
        {
          title: "复古智能电摩i-Like",
          zc: "  租电立减4400",
          src: require('@/assets/images/header/like.png'),
          url: "/ilikes"
        },
        {
          title: "赛道级电摩S7",
          zc: "   租电立减11000",
          src: require('@/assets/images/header/s7.png'),
          url: "/s7"
        },
        {
          title: "新世代电轻摩i-OneXS  ",
          zc: "  租电最高立减4400",
          src: require('@/assets/images/header/xs.png'),
          url: ""
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      var dom = $('#owl-demo')
      var sliderApp = $('#owl-demo').owlCarousel({
        center: false,
        items: 1,
        loop: true,
        margin: 0,
        autoWidth: true,
        // nav: false,
        responsive: {},
        smartSpeed: 500,
        dots: true,
        // navText: ['<i class="icon iconfont icon-back"></i>', '<i class="iconfont icon-more"></i>']

      });
      let dotDom = dom.find('.owl-dots');
      let dots = dotDom.find('.owl-dot');
      let numWrapper = '<div class="_dotNumberWrapper">';
      numWrapper += '<div class="_dotsWrapper">'
      dots.each((i, v) => {
        numWrapper += '<div class="_dot">' + getTwoNumber(i + 1) + '</div>'
      });
      numWrapper += '</div>';
      numWrapper += "<div class='_all'>" + getTwoNumber(dots.length + 1) + "</div>"
      numWrapper += '</div>';
      numWrapper = $(numWrapper).appendTo(dom.find('.content_list'));

      numWrapper.find('._dot').eq(0).addClass('active');

      dom.find('.owl-stage .owl-item.active').eq(1).find('.group_4 ').addClass('actives');
      sliderApp.on('changed.owl.carousel', function (event) {

        var item = dom.find('.owl-dots .owl-dot.active').index();
        dom.find('.owl-stage .owl-item.active').removeClass('actives')
        dom.find('.owl-stage .owl-item .group_4').removeClass('actives')

        var num = event.page.index += 1;
        if (num > 4) {
          num = 0;
        }
        $(`.a${num}`).addClass('actives');
        if ((item + 1) % 2 == 0) {
          $(dom).addClass('even');

        } else {

          $(dom).removeClass('even');
        }



        numWrapper.find('._dot').removeClass('active');
        numWrapper.find('._dot').eq(item).addClass('active');
      });



      function getTwoNumber(num) {
        return num < 10 ? '0' + num : num + '';
      };

    })

  },
  methods: {
    navPath(index) {
      console.log(index)
      this.$router.push({ path: index });
    }
  },
};
</script>
<style>
.mlist .owl-nav {
  display: none;
}
</style>
<style lang="scss" scoped>
.owl-carousel .owl-item .group_4 {
  opacity: 0.5;
}

.owl-stage .group_4.actives {
  opacity: 1;
}

.owl-stage>.actives .text_7 {
  color: #007aff;
}

.owl-stage .group_4.actives {
  opacity: 1;
}

.owl-stage .group_4.actives .text_7 {
  color: #007aff;
}
</style>
<style scoped lang="scss">
.sections {
  padding: 0 0 35px;
  background-color: #ffffff;
}

.space-y-82>*:not(:first-child) {
  margin-top:40px;
}

.group {
  padding: 0 151px;
}

.space-y-15>*:not(:first-child) {
  margin-top: 15px;
}

.image {
  flex: 1 1 249px;
  width: 180px;
  height: 180px;
  
}

.image_2 {
  flex: 1 1 249px;
  width: 244px;
  //height: 177px;
}

.image_3 {
  margin-left: 106px;
}

.image_4 {
  margin-left: 96px;
  margin-bottom: 3px;
  flex: 1 1 249px;
  width: 247px;
  height: 177px;
}

.image_6 {
  margin-left: 94px;
  margin-top: 12px;
  flex: 1 1 249px;
  width: 244px;
  height: 167px;
}

.image_5 {
  margin-left: 94px;
}

.group_2 {
  line-height: 14px;
  height: 14px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.font_1{
  font-size: 20px;
  font-family: "Albbr";
  line-height: 14px;
  color: #000000;
}

.font_2 {
  font-size: 14px;
  font-family: "Albbr";
  line-height: 14px;
  // color: #3478f6;
  margin-left: 8px;
}

.view {
  margin-left: 87px;
}

.view_2 {
  margin-left: 105px;
}

.view_3 {
  margin-left: 134px;
}

.text {
  // color: #007aff;
}

.text_2 {
  margin-left: 0;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.group_3 {
  align-self: center;
  width: 187px;
  padding-bottom: 10px;
}

.group_4 {
  padding-bottom: 6px;
  cursor: pointer;
}

.space-x-25>*:not(:first-child) {
  margin-left: 30px;
}

.font_3 {
  font-size: 20px;
  font-family: "Albbr";
  line-height: 19px;
}

.text_3 {
  color: #007aff;
}

.text_4 {
  color: #000000;
}

.divider {
  height: 3px;
  border-left: solid 76px #007aff;
  border-right: solid 76px #000000;
}
</style>