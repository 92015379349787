<template>
  <div>
    <Wapheader/> 
    <div class="flex-col page ilike">
  <div class="flex-col group">
    <div class="flex-col section_1">  
      <div class="grid">
        <div data-aos="fade-up" data-aos-delay="100" class="flex-col items-center grid-item">
          <span class="text">49km/h</span>
          <span class="text_2">极速（法规标准）</span>
        </div>
        <div data-aos="fade-up" data-aos-delay="200" class="flex-col items-center grid-item">
          <span class="text">1.5kw</span>
          <span class="text_4">最大功率</span>
        </div>
        <div data-aos="fade-up" data-aos-delay="300" class="flex-col items-center grid-item">
          <span class="text">75km</span>
          <span class="text_6">续航里程*（等速）</span>
        </div>
        <div data-aos="fade-up" data-aos-delay="400" class="flex-col items-center grid-item">
          <span class="text">48V28Ah</span>
          <span class="text_8">电池容量</span>
        </div>
        <div data-aos="fade-up" data-aos-delay="500" class="flex-col items-center grid-item">
          <span class="text">21700</span>
          <span class="text_10">汽车级电芯</span>
        </div>
        <div data-aos="fade-up" data-aos-delay="600" class="flex-col items-center grid-item">
          <span class="text">10kg</span>
          <span class="text_12">电池重量</span>
        </div>
      </div>
      <div data-aos="fade-up"  class="flex-col section_2">
        <div class="group_8">
          <span class="text_13">* 数据来自常州光阳研发中心实验室，实际使用中可能</span>
          <span class="text_14">因载重、气温、温度等客观条件的变化而有所差异。</span>
        </div>
      </div>
    </div>
    <div class="flex-col items-center image-wrapper">
      <img data-aos="fade-up"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745582340603985.png"
        class="image_2"
      />
    </div>
    <div class="flex-col items-center image-wrapper_1">
      <img data-aos="fade-up"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745582358058960.png"
        class="image_3"
      />
    </div>
    <div class="flex-row section_3">
      <div data-aos="fade-up" class="swiper swiper1">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/01.png" alt="">
    </div>
    <div class="swiper-slide">
      <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/02.png" alt="">
    </div>
  </div>
   <div class="swiper-pagination"></div>
</div>
    </div>
    <div class="flex-col section_4">
      <div class="flex-col items-start group_9">
        <div data-aos="fade-up">
          <span class="text_15">藏在细节里的</span>
          <span class="text_16">诗意魅力</span>
        </div>
        <img data-aos="fade-up" 
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745590056676905.png"
          class="image_7"
        />
        <img data-aos="fade-up"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745588837680438.png"
          class="image_8"
        />
      </div>
      <div data-aos="fade-up" class="flex-col items-center text-wrapper"><span class="text_17">反向曲面设计</span></div>
    </div>
    <div class="flex-row section_5">
      <div data-aos="fade-up" class="swiper swiper2">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <img src="@/assets/images/wap/like/1.png" alt="">
    </div>
    <div class="swiper-slide">
      <img src="@/assets/images/wap/like/2.png" alt="">
    </div>
    <div class="swiper-slide">
      <img src="@/assets/images/wap/like/3.png" alt="">
    </div>
  </div>
   <div class="swiper-pagination"></div>
</div>
    </div>
    <div class="flex-col items-center section_6">
      <img data-aos="fade-up"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745592188556523.png"
        class="image_12"
      />
      <img data-aos="fade-up"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745592376445541.png"
        class="image_13"
      />
    </div>
  </div>
  <div class="flex-col items-center section_7">
    <div data-aos="fade-up" class="group_11">
      <span class="text_18 text_19">K</span>
      <span class="text_18 text_20">YM</span>
      <span class="text_18 text_21">C</span>
      <span class="text_18 text_22">O独</span>
      <span class="text_23">家</span>
      <span class="text_24">舒适人体工学技</span>
      <span class="text_25">术</span>
    </div>
    <img data-aos="fade-up"
      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745594442350013.png"
      class="image_14"
    />
    <img data-aos="fade-up"
      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745595512749629.png"
      class="image_15"
    />
  </div>
  <div class="flex-col section_8">
    <div class="flex-col">
      <div class="flex-col group_13">
        <div class="flex-col items-center section_9">
          <div data-aos="fade-up" class="group_14">
            <span class="text_18 text_26">K</span>
            <span class="text_18 text_27">Y</span>
            <span class="text_18 text_28">MCO</span>
            <span class="text_29">大排量踏板摩托</span>
            <span class="text_30">车</span>
            <span class="text_31">同材铬钼钢</span>
            <span class="text_32">管</span>
          </div>
        </div>
        <div data-aos="fade-up" class="group_15">
          <span class="text_33">“大材小用”</span>
          <span class="text_34">环抱式车架结</span>
          <span class="text_35">构</span>
        </div>
      </div>
      <div class="flex-col items-start group_16">
        <img data-aos="fade-up"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745597291237988.png"
          class="image_16"
        />
        <img data-aos="fade-up"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745597560308442.png"
          class="image_17"
        />
      </div>
    </div>
    <div class="flex-col items-center group_17">
      <img data-aos="fade-up"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745597984731747.png"
        class="image_18"
      />
      <div class="group_18" >
        <span data-aos="fade-up" class="text_36">摩托车液压减</span>
        <span data-aos="fade-up" class="text_37">震</span>
        <span data-aos="fade-up" class="text_38">轻量化铝合金锻造平</span>
        <span data-aos="fade-up" class="text_39">叉</span>
      </div>
    </div>
  </div>
  <div class="flex-col group_19">
    <span data-aos="fade-up" class="text_40">缤纷色彩 任意选择</span>
    <div class="flex-col group_20">
      <div class="flex-col items-center justify-center group_21">
        <img data-aos="fade-up"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745598916451685.png"
          class="image_19"
          
        />
        <img data-aos="fade-up"
          :src="require(`@/assets/images/colors/${index}.png`)"
          class="image_20"
        />
      </div>
      <div data-aos="fade-up" class="justify-center group_22">
        <span class="text_41">爱玩标准版</span>
        <span class="text_42">i-OneX</span>
      </div>
      <div class="justify-center">
      <span v-show="index==1" data-aos="fade-up" class="text_43">雾海蓝</span>
      <span v-show="index==2" style="color: #ffbfc7;" data-aos="fade-up" class="text_43">莫兰迪粉</span>
      <span v-show="index==3" data-aos="fade-up" class="text_43"><span style="color: #ff001f;">熔岩红</span>  <span style="color: #a3a3a3;">时光白</span> </span>
      <span v-show="index==4" data-aos="fade-up" class="text_43" style="color: #000000;">暗夜黑</span>
      </div>
      <div class="justify-between equal-division">
        <img data-aos="fade-up"
        @click="index=1"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745599291937964.png"
          class="equal-division-item"
        />
        <img data-aos="fade-up"
        @click="index=2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745599878036434.png"
          class="equal-division-item"
        />
        <img data-aos="fade-up"
        @click="index=3"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745600681000317.png"
          class="equal-division-item"
        />
        <img data-aos="fade-up"
         @click="index=4"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745600705545550.png"
          class="equal-division-item"
        />
      </div>
      <div data-aos="fade-up" class="group_23">
        <span class="text_44">运动格调，精致智慧</br></span>
        <span class="text_45">由内而外自由体现</span>
      </div>
      <div data-aos="fade-up" class="flex-col items-center button"><span class="text_46">我要试驾</span></div>
    </div>
  </div>
  <div class="flex-col items-center group_24">
    <img data-aos="fade-up"
      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745601510661098.png"
      class="image_25"
    />
  </div>
</div>
<Wapfooter/>
  </div>
</template>
<script>
import Wapheader from '../../components/wapheader/wapheader.vue';
import Wapfooter from '../../components/wapfooter/wapfooter.vue';
import Aos from "aos";
export default {
  name: 'Vue3Ilike',
components: { Wapheader, Wapfooter },
  data() {
    return {
      index:1
    };
  },

  async mounted() {
     Aos.init({
     offset: 120,
      duration: 500,
      delay: 500,
      once: true,
    })
     await this.$nextTick(()=>{
      new Swiper('.swiper1', {
        autoplay: true,
       
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
      )
      new Swiper('.swiper2', {
        autoplay: true,
        
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }

      )
     });
  },

  methods: {
    
  },
};
</script>
<style lang="scss">

.ilike .swiper-pagination .swiper-pagination-bullet{
  background: #fff !important;
}
</style>
<style scoped>
.swiper{
  width: 100%;
}
.swiper-pagination{
  width: 20%;
}
.swiper-pagination .swiper-pagination-bullet{
  background: #fff !important;
}
.swiper-slide img{
  width: 100%;
  display: block;
}
.image_4 {
  width: 19px;
  height: 20px;
}
.text_18 {
  word-break: break-all;
}
.grid-item {
  padding: 27px 0;
}
.equal-division-item {
  width: 51px;
  height: 51px;
}
.text {
  color: #0085ff;
  font-size: 38px;
  
  line-height: 30px;
}
.page {
  
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.group {
  
}
.section_7 {
  margin: 84px 46px 0 45px;
  padding-top: 76px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745594009787372.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 1139px;
}
.section_8 {
  margin-top: 111px;
  padding-top: 116px;
  background-color: #000000;
}
.group_19 {
  margin-top: 112px;
  padding: 0 41px;
}
.section_1 {
  padding: 50px 52px 161px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745582350302137.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.image-wrapper {
  padding: 103px 0 799px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745582315367878.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.image-wrapper_1 {
  margin-top: -272px;
  padding: 124px 0 680px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745582348832304.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.section_3 {
  width: 100%;
}
.section_4 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745586115815116.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.section_5 {
  width: 100%;
}
.section_6 {
  padding: 138px 0 136px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745591529669501.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.group_11 {
  text-align: center;
  width: 388px;
}
.image_14 {
  margin-top: 60px;
  width: 616px;
  height: 431px;
}
.image_15 {
  margin: 65px 0 -16px;
  width: 584px;
  height: 378px;
}
.group_17 {
  margin-top: 156px;
  padding-top: 22px;
  position: relative;
}
.text_40 {
  align-self: center;
  color: #000;
  font-size: 42px;
  
  line-height: 41px;
}
.group_20 {
  
  margin-top: 63px;
}
.image_25 {
  width: 100vw;
  height: 320.4vw;
}
.grid {
  margin: 1138px 18.5px 0 16.5px;
  height: 246px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.section_2 {
  margin: 63px 29px 0;
  padding: 15px 0;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745582359457026.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.image_2 {
  width: 427px;
  height: 138px;
}
.image_3 {
  width: 419px;
  height: 135px;
}
.image_6 {
  margin-left: 32px;
}
.group_9 {
  padding: 100px 0 81px;
  align-self: center;
}
.text-wrapper {
  margin-right: 20px;
  padding: 56px 0 377px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745588764344735.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.image_10 {
  margin-left: 32px;
}
.image_11 {
  margin-left: 32px;
}
.image_12 {
  width: 452px;
  height: 49px;
}
.image_13 {
  margin-top: 667px;
  width: 542px;
  height: 344px;
}
.text_19 {
  color: #000;
  font-size: 48px;
  
  line-height: 72px;
}
.text_20 {
  color: #000;
  font-size: 48px;
  
  line-height: 72px;
}
.text_21 {
  color: #000;
  font-size: 48px;
  
  line-height: 72px;
}
.text_22 {
  color: #000;
  font-size: 48px;
  
  line-height: 72px;
}
.text_23 {
  color: #000;
  font-size: 48px;
  
  line-height: 72px;
}
.text_24 {
  color: #000;
  font-size: 48px;
  
  line-height: 72px;
}
.text_25 {
  color: #000;
  font-size: 48px;
  
  line-height: 72px;
}
.group_13 {
  padding-top: 50px;
  position: relative;
}
.group_16 {
  margin-top: 27px;
  padding: 0 65px;
}
.image_18 {
  width: 100vw;
  height: 142.5333vw;
}
.group_18 {
  text-align: center;
  width: 426px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.group_21 {
  padding-top: 388px;
  position: relative;
}
.group_22 {
  margin-top: 20px;
  padding: 0 147px;
}
.text_43 {
  
  margin-top: 34px;
  align-self: flex-end;
  color: #7ac0ff;
  font-size: 28px;
  
  line-height: 28px;
}
.equal-division {
  margin-top: 40px;
  align-self: center;
  width: 336px;
}
.group_23 {
  margin-top: 45px;
  align-self: center;
  line-height: 36px;
  text-align: center;
}
.button {
  margin-top: 46px;
  padding: 26px 0;
  align-self: center;
  border-radius: 46px;
  width: 332px;
  border: solid 4px #0b0306;
}
.image {
  margin: 5px 0;
  width: 321px;
  height: 30px;
}
.image_1 {
  margin-right: 5px;
  width: 48px;
  height: 40px;
}
.group_8 {
  margin-left: 14px;
  margin-right: 14px;
 
}
.image_7 {
  margin-top: 40px;
  width: 588px;
  height: 498px;
}
.image_8 {
  margin-top: 25px;
  width: 587px;
  height: 441px;
}
.text_17 {
  color: #ffffff;
  font-size: 32px;
  
  font-weight: 500;
  line-height: 31px;
}
.section_9 {
  padding: 66.5px 0 380.5px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745596133189955.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.group_15 {
  text-align: center;
  width: 310px;
  position: absolute;
  right: 209px;
  top: 0;
}
.image_16 {
  width: 620px;
  height: 159px;
}
.image_17 {
  margin-top: 44px;
  width: 467px;
  height: 420px;
}
.text_36 {
  color: #ffffff;
  font-size: 42px;
  
  line-height: 50px;
}
.text_37 {
  color: #ffffff;
  font-size: 42px;
  
  line-height: 50px;
}
.text_38 {
  color: #ffffff;
  font-size: 42px;
  
  line-height: 50px;
}
.text_39 {
  color: #ffffff;
  font-size: 42px;
  
  line-height: 50px;
}
.image_19 {
  width: 633px;
  height: 166px;
}
.image_20 {
  width: 460px;
  height: 500px;
  position: absolute;
  right: 69px;
  top: 0;
}
.text_41 {
  color: #000;
  font-size: 32px;
  
  line-height: 30px;
}
.text_42 {
  margin-bottom: 3px;
  color: #000;
  font-size: 32px;
  
  line-height: 30px;
}
.text_44 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}
.text_45 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}
.text_46 {
  color: #000;
  font-size: 32px;
  
  line-height: 32px;
}
.text_2 {
  margin-top: 15px;
  color: #000;
  font-size: 24px;
  
  line-height: 23px;
}
.text_4 {
  margin-top: 16px;
  color: #000;
  font-size: 24px;
  
  line-height: 23px;
}
.text_6 {
  margin-top: 16px;
  color: #000;
  font-size: 24px;
  
  line-height: 23px;
}
.text_8 {
  margin-top: 16px;
  color: #000;
  font-size: 24px;
  
  line-height: 23px;
}
.text_10 {
  margin-top: 16px;
  color: #000;
  font-size: 24px;
  
  line-height: 23px;
}
.text_12 {
  margin-top: 9px;
  color: #000;
  font-size: 24px;
  
  line-height: 23px;
}
.text_13 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}
.text_14 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}
.text_15 {
  color: #000;
  font-size: 48px;
  
  line-height: 60px;
}
.text_16 {
  color: #000;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 60px;
}
.group_14 {
  line-height: 36px;
  text-align: center;
  width: 292px;
}
.text_33 {
  color: #ffffff;
  font-size: 42px;
  
  line-height: 50px;
  font-weight: bold;
}
.text_34 {
  color: #ffffff;
  font-size: 42px;
  font-weight: bold;
  line-height: 50px;
}
.text_35 {
  color: #ffffff;
  font-size: 42px;
  font-weight: bold;
  line-height: 50px;
}
.text_26 {
  color: #ffffff;
  font-size: 25.5px;
  
  line-height: 36px;
}
.text_27 {
  color: #ffffff;
  font-size: 25.5px;
  
  line-height: 36px;
}
.text_28 {
  color: #ffffff;
  font-size: 25.5px;
  
  line-height: 36px;
}
.text_29 {
  color: #ffffff;
  font-size: 25.5px;
  
  line-height: 36px;
}
.text_30 {
  color: #ffffff;
  font-size: 25.5px;
  
  line-height: 36px;
}
.text_31 {
  color: #ffffff;
  font-size: 25.5px;
  
  line-height: 36px;
}
.text_32 {
  color: #ffffff;
  font-size: 25.5px;
  
  line-height: 36px;
}
.group_24 {
  margin-top: 100px;
  height: 2402px;
}
</style>