<template>
  <div class="flex-col fixeds">
    <div  v-if="show==0" class="flex-row justify-between section_2">
      <img
      @click="show=2"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/7aac57f92046566f1b38145db82c5d21.png"
        class="image"
      />
      <img
       @click="navPath('/newwap')"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005966223060878.png"
        class="image_1"
      />
      <a >
        <img
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005966407546710.png"
        class="image_2"
      />
      </a>
    </div>
    <wapheaderzk v-model="show" :show="show" v-if="show==1" /> 
    <f v-model="show" :show="show" v-if="show==2"/> 
    
  </div>
</template>

<script>
  import f from "./f.vue"
  import wapheaderzk from "./wapheaderzk.vue"
  export default {
    components: {f,wapheaderzk},
    data() {
      return {
        show:0
      };
    },
    methods: {},
  };
</script>

<style scoped lang="css">
  .section_2 {
  padding: 30px 30px 29px;
  background-color: #00000126;
}
.image {
  margin-bottom: 3px;
  width: 40px;
  height: 36px;
}
.image_1 {
  width: 260px;
  height: 41px;
}
.image_2 {
  width: 40px;
  height: 40px;
}
.fixeds{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
</style>