import Vue from 'vue';
import VueRouter from 'vue-router';

import like from "@/pages/wap/ilike.vue"
import x from "@/pages/wap/x.vue"
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'indexs',
    component: ()=>import('@/pages/index/index.vue'),
  },
  {
    path: '/ht',
    name: 'ht',
    component: ()=>import('@/pages/ht.vue'),
  },
  {
    path: '/newwapcnw',
    name: 'newwapcnw',
    component: ()=>import('@/pages/newwap/cnw.vue'),
  },
  {
    path: '/newwapdt',
    name: 'newwapdt',
    component: ()=>import('@/pages/newwap/dt.vue'),
  },
  {
    path: '/newwapyy',
    name: 'newwapyy',
    component: ()=>import('@/pages/newwap/yy.vue'),
  },
  {
    path: '/newwapcdyw',
    name: 'newwapcdyw',
    component: ()=>import('@/pages/newwap/cdyw.vue'),
  },
  {
    path: '/newwapbrand',
    name: 'newwapbrand',
    component: ()=>import('@/pages/newwap/brand.vue'),
  },
  {
    path: '/newwaplx',
    name: 'newwaplx',
    component: ()=>import('@/pages/newwap/lx.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: ()=>import('@/pages/lx.vue'),
  },
  {
    path: '/rent',
    name: 'rent',
    component: ()=>import('@/pages/zd.vue'),
  }
  ,
  {
    path: '/drive',
    name: 'drive',
    component: ()=>import('@/pages/yy.vue'),
  }
  ,
  {
    path: '/ionex',
    name: 'ionex',
    component: ()=>import('@/pages/cn.vue'),
  }
  ,
  {
    path: '/news',
    name: 'news',
    component: ()=>import('@/pages/news.vue'),
  }
  ,
  {
    path: '/i-onex',
    name: 'i-onex',
    component: ()=>import('@/pages/ionex.vue'),
  },
  {
    path: '/i-onexs',
    name: 'i-onexs',
    component: ()=>import('@/pages/ionexs.vue'),
  },
  {
    path: '/f9',
    name: 'f9',
    component: ()=>import('@/pages/f9.vue'),
  },
  {
    path: '/i-like',
    name: 'i-like',
    component: ()=>import('@/pages/ilike.vue'),
  },
  {
    path: '/html/:url',
    name: 'html',
    component: ()=>import('@/pages/html.vue'),
  },
  {
    path: '/s7',
    name: 's7',
    component: ()=>import('@/pages/s7.vue'),
  },
  {
    path: '/s7s',
    name: 's7s',
    component: ()=>import('@/pages/pc/s7.vue'),
  },
  {
    path: '/brand',
    name: 'brand',
    component: ()=>import('@/pages/brand.vue'),
  },
  {
    path: '/x',
    name: 'x',
    component: x,
  }

  ,{
    path: '/new',
    name: 'new',
    component: ()=>import('@/pages/pc/new.vue'),
  }
  ,{
    path: '/wapnew',
    name: 'wapnew',
    component: ()=>import('@/pages/wap/new.vue'),
  }
  ,{
    path: '/index',
    name: 'index',
    component: ()=>import('@/pages/pc/index.vue'),
  }
  
  
  ,{
    path: '/demo',
    name: 'demo',
    component: ()=>import('@/pages/pc/demo.vue'),
  },{
    path: '/newwap',
    name: 'newwap',
    component: ()=>import('@/pages/newwap/index.vue'),
  },
  {
    path: '/newwapilike',
    name: 'newwapilike',
    component: ()=>import('@/pages/newwap/ilike.vue'),
  }
  ,
  {
    path: '/newwapx',
    name: 'newwapx',
    component: ()=>import('@/pages/newwap/x.vue'),
  }
  ,
  {
    path: '/newwaps7',
    name: 'newwaps7',
    component: ()=>import('@/pages/newwap/s7.vue'),
  }
  ,
  
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});



router.afterEach((to,from,next)=>{
  
  window.scrollTo(0,0)
})
export default router;