<template>
  <div>
    <div class="flex-col page">
      <div class="flex-row justify-between header">
        <div class="header-left" @click="close(0)">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005963760008322.png"
            class="image" />
        </div>
        <div class="header-middle" @click="navPath('/newwap')">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005963331180599.png"
            class="image_1" />
        </div>
        <div class="header-right">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005964236202680.png"
            class="image_2" />
        </div>

      </div>
      <div class="flex-col group">
        <div class="flex-col space-y-83 section_1">
          <span @click="close(1)" class="text">
            探索车型
            <br />
          </span>
          <div>
            <span class="text_1 tab1" @click="gocnw">
              车能网
              <br />
            </span>
          </div>
          <div>
            <span class="text_2 tab2" @click="gocdyw">
              租电业务
              <br />
            </span>
          </div>
          <div>
            <span class="button tab3" @click="gopp">
              品牌
              <br />
            </span>
          </div>
          <div>
            <span class="button_1 tab4" @click="godt">
              动态
              <br />
            </span>
          </div>
          <div>
            <span class="button_1 tab5" @click="golx">
              联系
              <br />
            </span>
          </div>


        </div>
        <div class="items-center justify-around tab">
          <div @click="navPath('/newwapyy')">预约试驾</div>
          <div>APP下载</div>
          <div>购车商城</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ["show"],
  name: 'Vue3F',

  data() {
    return {

    };
  },

  mounted() {
    console.log(this.$route)
    if (this.$route.name == 'newwapcnw') {
      $('.tab1').addClass("active")
      return
    }
    if (this.$route.name == 'newwapcdyw') {
      $('.tab2').addClass("active")
      return
    }
    if (this.$route.name == 'newwapbrand') {
      $('.tab3').addClass("active")
      return
    }
    if (this.$route.name == 'newwapdt') {
      $('.tab4').addClass("active")
      return
    }
    if (this.$route.name == 'newwaplx') {
      $('.tab5').addClass("active")
      return
    }
  },

  methods: {
    close(index) {
      this.$emit('input', index)
    },
    golx() {
      if (this.$route.name == 'newwaplx') {
        this.close(0)
      }else{
        this.$router.push("/newwaplx")
      }
    },
    gopp() {
 
      if (this.$route.name == 'newwapbrand') {
        this.close(0)
      }else{
        this.$router.push("/newwapbrand")
      }
    },
    gocdyw() {
    
      if (this.$route.name == 'newwapcdyw') {
        this.close(0)
      }else{
        this.$router.push("/newwapcdyw")
      }
    },
    godt() {
      
      if (this.$route.name == 'newwapdt') {
        this.close(0)
      }else{
        this.$router.push("/newwapdt")
      }
    },
    gocnw() {
      if (this.$route.name == 'newwapcnw') {
        this.close(0)
      }else{
        this.$router.push("/newwapcnw")
      }
     
    }
  }
};
</script>

<style scoped>
.active {
  color: #007EFE !important;
  padding-bottom: 10px;
  border-bottom: 6px solid #007EFE !important;
}

.tab {
  font-family: 'Albbr';
  font-size: 30px;
  line-height: 150px;
  color: #444444;
}

.header-left {
  width: 100px;
  text-align: center;
}

.header-right {
  width: 100px;
  text-align: center;
}

.page {

  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  background-image: url(../../assets/images/fbanner.png);
  background-size: 100% 100%;
}

.header {
  /* padding: 30px 30px 29px; */
  background-color: #ffffff;
  height: 100px;
  position: relative;
  line-height: 100px;
}

.image {
  margin-bottom: 3px;
  width: 40px;
  height: 36px;
}

.image_1 {
  width: 260px;
  height: 41px;
}

.image_2 {
  width: 40px;
  height: 40px;
}

.group {
  /* padding-bottom: 6190px; */
  flex: 1 1 auto;
  overflow-y: auto;

}

.space-y-83>*:not(:first-child) {
  margin-top: 83px;
}

.section_1 {
  padding: 141.5px 78px 170px;
}

.text {
  color: #000;
  font-size: 48px;

  font-weight: 500;
  line-height: 67px;
}

.text_1 {
  color: #000;
  font-size: 48px;

  font-weight: 500;
  line-height: 67px;
}

.text_2 {
  color: #000;
  font-size: 48px;

  font-weight: 500;
  line-height: 67px;
}

.button {
  color: #000;
  font-size: 48px;

  font-weight: 500;
  line-height: 67px;
}

.button_1 {
  color: #000;
  font-size: 48px;

  font-weight: 500;
  line-height: 67px;
}

.button_2 {
  color: #007aff;
  font-size: 48px;

  font-weight: 500;
  line-height: 67px;
}
</style>