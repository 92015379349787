import Vue from 'vue';
import App from './app.vue';
import router from './router';
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import './assets/fonts/fonts.css';
import ModalWizard from 'vue-modal-wizard';
import newheader from "./components/newheader/newheader.vue";
import VueWaypoint from 'vue-waypoint'
import VueScrollmagic from 'vue-scrollmagic'
import newzkheader from "./components/newzkheader/newzkheader.vue"
import Newheader from '@/components/newheader/newheader.vue';
import newfooter from '@/components/newfooter/newfooter.vue';
import Wapheader from '@/components/wapheader/wapheader.vue';
import Wapfooter from '@/components/wapfooter/wapfooter.vue';
import VueAMap from "vue-amap";
import VueTypedJs from 'vue-typed-js'
import WujieVue from "wujie-vue2"

Vue.use(WujieVue);
Vue.use(VueTypedJs) 
Vue.use(VueAMap);
Vue.component("Wapheader",Wapheader)
Vue.component("Wapfooter",Wapfooter)
Vue.component("Newheader",Newheader)
Vue.component("newzkheader",newzkheader)
Vue.component("newfooter",newfooter)
Vue.use(ModalWizard)

Vue.use(AOS);
Vue.config.productionTip = false;


Vue.use(VueScrollmagic)
 
// Waypoint plugin
Vue.use(VueWaypoint)

Vue.mixin({
  mounted(){
    AOS.init({
      offset: 0,
      duration: 600,
      delay: 600,
      once: true,
    })
  }
})
Vue.prototype.navPath = (path) => {
  console.log(path)
  router.push({ path });
};
Vue.prototype.navUrl = (path) => {
  window.location.href=path
};
Vue.prototype.play = (url) => {

  Vue.prototype.$bus.$emit('playvideo',{show:true,url})
  
};

new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus=this;
  }
}).$mount('#app');

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: "683935bf266894adb831eb2a725f73d2",  // 换成你自己的key,这是我随便写的
  // 插件集合
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: "1.4.4",
});
