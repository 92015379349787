<template>
  <div>
    <div class="flex-col section_12">
      <div class="justify-between group_30">
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316208115921.png"
          class="image_22"
        />
        <div class="flex-row group_31">
          <span class="text_36" style="font-family: Albbr;" @click="navPath('/drive')">预约试驾</span>
          <a href="http://zydadmin.kymco.life/index.php/admin/login/index.html">
            <span class="text_38" style="font-family: Albbr; cursor: pointer;">工作后台</span>
          </a>
        </div>
      </div>
      <div class="flex-col group_32">
        <div class="justify-between">
          <div class="flex-col group_34">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316223612676.png"
              class="image_23"
            />
            <div class="group_35">
              <div class="text_39">营销电话: 0519-68197852</div>
              <div class="text_40">服务热线: 400-808-0395</div>
              <div class="text_41">企业邮箱: ev@mail.kymco.com.cn</div>
            </div>
          </div>
          <img
            @click="goBack"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316215117664.png"
            class="image_24"
          />
        </div>
        <span class="text_42"
          ><a
            class="text_42"
            href="https://kymco.com.cn/"
            target="_blank"
            rel="noopener noreferrer"
            >光阳摩托</a
          >
          /
          <a
            class="text_42"
            href="https://www.ionex.com.tw/#view1"
            target="_blank"
            rel="noopener noreferrer"
          >
            ionex台湾</a
          >
          /
          <a
            class="text_42"
            href="https://www.kymco.com.tw/"
            target="_blank"
            rel="noopener noreferrer"
            >光陽臺灣</a
          >
          /
          <a
            class="text_42"
            href="https://www.kymco.com/international"
            target="_blank"
            rel="noopener noreferrer"
            >KYMCO Global</a
          >
        </span>
        <div class="justify-between group_36">
          <div class="group_37">
            <span class="text_43"
              >Copyright © 2022 杭州英捷鑫科技有限公司&ensp;&ensp;All rights
              reserved</span
            >
            <span class="text_44">.</span>
            <span class="text_45">&ensp;&ensp;浙ICP备20029190号</span>
            <br />
            <br />
            <span class="text_46">Design by</span>
            <a href="http://www.hansn.cn/"
              ><span class="text_47">&ensp;HANSN</span></a
            >
          </div>
          <div class="flex-row group_38">
            <div class="wx-content1" v-if="isshowwx1">
              <img  class="images"  src="../../assets/images/ewm.jpg"/>
            </div>
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016330808267248.png"
              class="image_6"  @mouseleave="wxout1" @mousemove="wxin1"
            />
            <div class="wx-content2" v-if="isshowwx">
              <img  class="images"  src="../../assets/images/wx.png"/>
            </div>
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016330762903778.png"
              @mouseleave="wxout" @mousemove="wxin" class="image_26"
            />
            <a href="https://weibo.com/u/7697284960"
            target="_blank">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316224251993.png"
                class="image_27"
              />
            </a>
            <a
              href="https://www.douyin.com/user/MS4wLjABAAAAdWMSrkFIpLbCu7HmLrcj8p4bIWQN_jZLgqdWiydzJyUL7qELp9G-ZmsxtvfikkuL" target="_blank"
            >
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316223373224.png"
                class="image_28"
              />
            </a>
            <a
              href="https://www.xiaohongshu.com/user/profile/55f6818962a60c265cdd1599?xhsshare=CopyLink&appuid=6117d683000000000101db7c&apptime=1669014013" target="_blank"
            >
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316221464315.png"
                class="image_6 image_29"
              />
            </a>
            <a
              href="https://kymco.tmall.com/?spm=a220o.1000855.1997427721.d4918089.4bcc5032n22gkh" target="_blank"
            >
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316224204316.png"
                class="image_30"
              />
            </a>
            <a href="https://mall.jd.com/index-12058581.html" target="_blank">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316226406954.png"
                class="image_6 image_31"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Vue3Newfooter",

  data() {
    return {
      isshowwx:false,
      isshowwx1:false
    };
  },

  mounted() {},

  methods: {
    goBack() {
      document.documentElement.scrollTop = 0;
    },
    wxin(){
      this.isshowwx=true
    },
    wxout(){
      this.isshowwx=false
    },
    wxin1(){
      this.isshowwx1=true
    },
    wxout1(){
      this.isshowwx1=false
    }
  },
};
</script>

<!-- <style scoped>
.swiper-pagination-bullet {
  background-color: transparent !important;
}
</style> -->
<style scoped lang="css">
.text_8 {
  margin-left: 24px;
}

.swiper-pagination {
  bottom: 120px;
}

.swiper-container {
  position: relative;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.text_19 {
  margin-top: 55px;
  color: #ffffff;
  font-size: 20px;

  line-height: 20px;
}

.text_17 {
  color: #ffffff;
  font-size: 28px;

  line-height: 40px;
}

.text_18 {
  color: #ffffff;
  font-size: 28px;

  line-height: 40px;
}

.text_20 {
  margin-top: 29px;
  color: #000;
  font-size: 28px;

  line-height: 27px;
}

.text_21 {
  color: #000;
  font-size: 24px;
  font-family: "Albbr";
  line-height: 36px;
}

.text_22 {
  color: #000;
  font-size: 24px;
  font-family: "Albbr";
  line-height: 36px;
}

.text_23 {
  color: #007aff;
  font-size: 20px;

  line-height: 19px;
}

.image_20 {
  margin-left: 0;
}

.text_27 {
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.text_28 {
  margin-left: 109px;
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.text_29 {
  margin-left: 57px;
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.text_30 {
  color: #000;
  font-size: 14px;

  line-height: 14px;
}

.view_2 {
  align-self: flex-start;
}

.view_3 {
  align-self: center;
}

.view_4 {
  align-self: flex-end;
}

.text_33 {
  color: #000;
  font-size: 14px;

  line-height: 10px;
}

.text_34 {
  color: #000;
  font-size: 14px;

  line-height: 10px;
}

.text_35 {
  color: #000;
  font-size: 14px;

  line-height: 10px;
}

.text_42 {
  margin-top: 29px;
  align-self: flex-start;
  color: #ffffff;
  font-size: 14px;

  line-height: 14px;
}

.text_24 {
  color: #000;
  font-size: 58px;
  font-weight: 700;
  line-height: 75px;
}

.text_25 {
  color: #000;
  font-size: 27px;

  line-height: 66px;
}

.text_26 {
  color: #000;
  font-size: 27px;

  line-height: 40px;
}

.text_31 {
  color: #000;
  font-size: 14px;

  line-height: 15px;
}

.text_32 {
  margin-left: 177px;
  color: #000;
  font-size: 14px;

  line-height: 15px;
}

.text_36 {
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  line-height: 18px;
}

.text_37 {
  margin-left: 47px;
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.text_38 {
  margin-left: 48px;
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.text_6 {
  margin-top: 34px;
  color: #ffffff;
  font-size: 36px;

  line-height: 34px;
}

.text_10 {
  color: #000;
  font-size: 36px;

  font-weight: 700;
  line-height: 43px;
}

.text_11 {
  color: #000;
  font-size: 36px;

  font-weight: 700;
  line-height: 43px;
}

.text_12 {
  color: #000;
  font-size: 24px;

  line-height: 30px;
}

.text_13 {
  color: #000;
  font-size: 24px;

  font-weight: 700;
  line-height: 30px;
}

.text_14 {
  color: #000;
  font-size: 24px;

  line-height: 30px;
}

.text_15 {
  color: #007aff;
  font-size: 20px;

  line-height: 20px;
}

.text_16 {
  color: #000;
  font-size: 20px;

  line-height: 19px;
}

.text_43 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_44 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_45 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_46 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_47 {
  color: #ffffff;
  font-size: 12px;

  font-weight: 700;
  line-height: 10px;
}

.text_7 {
  color: #ffffff;
  font-size: 20px;

  line-height: 20px;
}

.text_9 {
  color: #ffffff;
  font-size: 20px;

  line-height: 19px;
}

.text_39 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.text_40 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.text_41 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.group_15 {
  margin-top: 90px;
}

.section_1 {
  padding-bottom: 61px;

  position: relative;
}

.group_8 {
  padding: 30px 100px 0;
}

.section_6 {
  padding: 219px 0 218px;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016335177762523.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_17 {
  margin-top: 100px;
  align-self: center;
}

.group_22 {
  margin-top: 87px;
}

.image_33 {
  width: 100vw;
}

.group_42 {
  position: absolute;
  left: 0;
  top: 0;
}

.image_9 {
  flex: 1 1 auto;
  width: 980px;
  height: 550px;
}

.section_4 {
  padding: 153.5px 60px 58px 88px;
  flex-shrink: 0;
  background-color: #eaeaea;
  width: 740px;
  height: 550px;
}

.image_13 {
  width: 629px;
  height: 58px;
}

.group_16 {
  margin-top: 42px;
  text-align: center;
}

.section_7 {
  margin-top: 5px;
  background-color: #ffffff;
  width: 76px;
  height: 3px;
}

.image_15 {
  margin-top: 48px;
  width: 1200px;
  height: 550px;
}

.group_19 {
  margin-top: 51px;
  width: 685px;
}

.section_9 {
  padding: 150px 94px 179px 359px;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016333222784286.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section_10 {
  padding: 98px 159px 76px;
  background-color: #eaeaea;
}

.section_12 {
  padding: 90px 96px 140px 100px;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316207594965.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.group_2 {
  margin-left: 202px;
  margin-top: 352px;
  align-self: flex-start;
  width: 832px;
}

.group_14 {
  margin-top: 63px;
}

.image_14 {
  width: 431px;
  height: 41px;
}

.group_20 {
  line-height: 36px;
  text-align: center;
}

.group_21 {
  margin-top: 47px;
}

.group_23 {
  margin-top: 121.5px;
  width: 444px;
}

.image_17 {
  width: 826px;
  height: 751px;
}

.group_26 {
  margin-top: 30px;
}

.group_27 {
  margin-top: 12px;
}

.divider {
  margin-top: 28px;
}

.group_29 {
  padding: 21px 0;
  align-self: flex-start;
  width: 1178px;
}

.group_30 {
  padding-bottom: 31px;
  border-bottom: solid 1px #ffffff;
}

.group_32 {
  margin-top: 48px;
}

.group_7 {
  margin-top: 335px;
}

.group_10 {
  align-self: flex-start;
  width: 367px;
}

.group_13 {
  margin-top: 43px;
}

.image_4 {
  width: 20px;
  height: 20px;
}

.image_10 {
  margin: 7px 0 9px 443px;
}

.image_6 {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.image_11 {
  margin-left: 37px;
}

.image_12 {
  margin: 7px 0 9px 36px;
}

.section_8 {
  margin-top: 6px;
  background-color: #007aff;
  width: 76px;
  height: 3px;
}

.image_16 {
  margin-top: 82px;
  align-self: flex-start;
  width: 145px;
  height: 145px;
}

.image_18 {
  margin-left: 50px;
  flex: 1 1 500px;
  width: 500px;
  height: 340px;
}

.group_28 {
  margin-right: 159px;
}

.section_11 {
  background-color: #000000;
  width: 500px;
  height: 1px;
}

.image_22 {
  width: 191px;
  height: 31px;
}

.group_31 {
  margin: 7px 0 6px;
  align-items: center;
}

.group_36 {
  margin-top: 13px;
}

.group_5 {
  margin-top: 53px;
}

.image_34 {
  margin: 7px 37px 9px 0;
}

.image_35 {
  margin-right: 36px;
}

.image_8 {
  margin: 7px 0 9px;
}

.group_11 {
  align-self: flex-start;
}

.group_12 {
  margin-top: 20.5px;
  line-height: 30px;
}

.group_6 {
  padding-bottom: 5px;
}

.section_5 {
  margin-left: 106px;
  align-self: flex-start;
  background-color: #000000;
  width: 76px;
  height: 3px;
  border-left: solid 76px #007aff;
}

.group_34 {
  width: 260px;
}

.image_24 {
  margin-right: 4px;
  width: 100px;
  height: 100px;
}

.group_37 {
  margin-top: 23px;
  line-height: 13px;
  height: 13px;
}

.group_38 {
  margin-right: 4px;
  position: relative;
}
.wx-content1 {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #fff;
  top: -120px;
  left: -35px;
}
.wx-content1::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
  border: 10px solid transparent;

  border-top: 10px solid #fff;
}
.wx-content2 {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #fff;
  top: -120px;
  left: 50px;
}
.wx-content2::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
  border: 10px solid transparent;

  border-top: 10px solid #fff;
}
.image_3 {
  width: 440px;
  height: 77px;
}

.section_3 {
  margin-left: 105px;
  align-self: flex-start;
  background-color: #ffffff;
  width: 76px;
  height: 3px;
  border-left: solid 76px #ffffff;
}

.image_23 {
  width: 260px;
  height: 22px;
}

.group_35 {
  margin-top: 15px;
  line-height: 26px;
}

.image_26 {
  margin-left: 41px;
  width: 40px;
  height: 33px;
  cursor: pointer;
}

.image_27 {
  margin-left: 41px;
  width: 41px;
  height: 34px;
}

.image_28 {
  margin-left: 41px;
  width: 30px;
  height: 36px;
}

.image_29 {
  margin-left: 41px;
}

.image_30 {
  margin-left: 41px;
  width: 39px;
  height: 36px;
}

.image_31 {
  margin-left: 41px;
}
.images{
  width: 100%;
  height: 100%;
  padding: 5px;
}
</style>
