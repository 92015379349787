<template>
  <!-- @mouseenter="show=false" @mouseleave="show=true" -->
<div  >
<div  class="fixeds"  @mouseleave="show=true">
  <div v-show="show" class="group_3">
    <div class="justify-between section_2">
      <img
        @click="navPath('/')"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016340998638437.png"
        class="image cur"
      />
      <div class="flex-row group_1">
        <span @mouseenter="sets" class="text ">探索车型</span>
        <span class="text_1" @click="navPath('/ionex')">车能网</span>
        <span class="text_2" @click="navPath('/rent')">租电业务</span>
        <span class="text_3" @click="navPath('/brand')">品牌</span>
        <span class="text_3" @click="navPath('/news')">动态</span>
        <span class="text_5" @click="navPath('/contact')">联系</span>
        <a href="http://zydadmin.kymco.life/index.php/admin/login/index.html
           ">
            <img
           src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016341849358769.png"
          class="image_1"  style="cursor:pointer;"
        />
        </a>
         

      </div>
      
    </div>
  </div>
   <newzkheader :show="show" />
   
   </div>
</div>
  
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        show:true
      };
    },
    methods: {
      sets(){
        this.show=false; 
      }
    },
  };
</script>

<style scoped lang="css">
 .hehe-enter,.hehe-leave-to{
            opacity: 0;
        }
        .hehe-enter-to,.hehe-leave{
            opacity: 1;
        }
        .hehe-enter-active,.hehe-leave-active{
            transition: all 3s;
        }

.active{
  position: relative;
}
.group_1>span{
  position: relative;
  cursor: pointer;
}
.group_1>span:hover::after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
}

.active::after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
}
  .group_3 {
    height: 70px;
    
  }
  .fixeds{
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    right: 0;
  }
  .section_2 {
    padding: 20px 100px;
    background-color: #00000126;
    
  }
  .image {
    width: 55px;
    height: 30px;
  }
  .group_1 {
    margin: 4px 0;
  }
  .image_2 {
    width: 6px;
    height: 6px;
    position: absolute;
    right: 918px;
    bottom: 12px;
  }
  .text {
    color: #ffffff;
    font-size: 20px;
    font-family: 'Albbr';
  
  }
  .text_1 {
    margin-left:  79px;
    color: #ffffff;
    font-size: 20px;
    font-family: 'Albbr';
   
  }
  .text_2 {
    margin: 0 0 0 72px;
    color: #ffffff;
    font-size: 20px;
    font-family: 'Albbr';
    
  }
  .text_3 {
    margin-left: 72px;
    color: #ffffff;
    font-size: 20px;
    font-family: 'Albbr';
   
  }
  .text_4 {
    margin:0 0 0 78px;
    color: #ffffff;
    font-size: 20px;
    font-family: 'Albbr';
   
  }
  .text_5 {
    margin-left: 74px;
   
    color: #ffffff;
    font-size: 20px;
    font-family: 'Albbr';
   
  }
  .image_1 {
    margin-left: 77px;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
  }
</style>