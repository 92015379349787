<template>
  <div class="flex-col page">
    <div class="flex-col section_1">
      <div class="flex-row justify-between section_2">
        <img @click="close(0)" class="image"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005963760008322.png" />
        <img @click="navPath('/newwap')" class="image_1"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005963331180599.png" />
        <img class="image_2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005964236202680.png" />
      </div>
      <div class="flex-col section_3">
        <div class="flex-row space-x-10 group">
          <img class="image_3"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633215633601287249.png" />
          <span @click="close(2)" class="text">返回</span>
        </div>
        <span class="text_1">探索车型</span>
        <div class="swiper swipercar">
          <div class="swiper-wrapper">
            <div v-for="t in list" @click="navPath(t.url)" class="swiper-slide">
              <div class="flex-col items-center space-y-33 group_1">
                <img class="image_4" :src="t.src" />
                <div class="group_2">
                  <span class="text_2">
                    {{t.title}}
                    <br />
                  </span>
                  <span class="text_3"> {{t.zc}}</span>
                </div>
              </div>
            </div>

          </div>
          <div class="swiper-pagination"></div>
        </div>


      </div>
      <div class="flex-col">
        <div class="flex-col section_4">
          <div class="flex-row justify-between group_4">
            <span class="text_4">了解更多</span>
            <span class="text_5" @click="navPath('/newwapyy')">预约试驾</span>
          </div>
          <div class="flex-col">
            <div class="section_5"></div>
            <div class="section_6"></div>
          </div>
        </div>
        <div class="section_7"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.swipercar {
  margin: 0;
  .swiper-pagination-bullet{
    
  }
}
</style>
<script>
export default {
  props: ["show"],
  components: {},
  data() {
    return {
      list: [
        {
          title: "电动顶级仿赛街跑F9",
          zc: "   ",
          src: require('@/assets/images/header/f9.png'),
          url: ""
        },
        {
          title: "新世代电轻摩i-OneX",
          zc: " 租电最高立减4400",
          src: require('@/assets/images/header/x.png'),
          url: "/newwapx"
        },
        {
          title: "复古智能电摩i-Like",
          zc: "  租电立减4400",
          src: require('@/assets/images/header/like.png'),
          url: "/newwapilike"
        },
        {
          title: "赛道级电摩S7",
          zc: "   租电立减11000",
          src: require('@/assets/images/header/s7.png'),
          url: "/newwaps7"
        },
        {
          title: "新世代电轻摩i-OneXS  ",
          zc: "  租电最高立减4400",
          src: require('@/assets/images/header/xs.png'),
          url: ""
        }
      ]
    };
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        new Swiper('.swipercar', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
          },
        })
      })
    })

  },

  methods: {
    close(index) {
      this.$emit('input', index)
    }
  },
};
</script>

<style scoped lang="css">
.image-wrapper {
  width: 36px;
}

.image_5 {
  width: 20px;
  height: 20px;
}

.page {
  padding-bottom: 6190px;
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section_1 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626007993025293563.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section_2 {
  padding: 30px 30px 29px;
  background-color: #ffffff;
}

.section_3 {
  padding: 87px 52px 50px;
  background-color: #ffffff;
}

.image {
  margin-bottom: 3px;
  width: 40px;
  height: 36px;
}

.image_1 {
  width: 260px;
  height: 41px;
}

.image_2 {
  width: 40px;
  height: 40px;
}

.group {
  padding: 0 33px;
}

.text_1 {
  margin-top: 39px;
  align-self: center;
  color: #000;
  font-size: 48px;

  font-weight: 500;
  line-height: 45px;
}

.group_1 {
  margin-top: 66px;
}

.group_3 {
  margin-top: 84px;
}

.section_4 {
  padding-bottom: 15px;
  background-color: #ffffff;
}

.section_7 {
  background-color: #ffffff;
  height: 118px;
}

.image_3 {
  width: 16px;
  height: 24px;
}

.text {
  color: #000;
  font-size: 28px;

  line-height: 25px;
}

.image_4 {
  width: 646px;
  height: 462px;
}

.group_2 {
  text-align: center;
  margin-bottom: 100px;
}

.view {
  margin: 7px 0 9px;
}

.view_1 {
  margin: 7px 0 9px 19px;
}

.view_2 {
  margin-left: 27px;
}

.view_3 {
  margin: 7px 0 9px 26px;
}

.view_4 {
  margin: 7px 0 9px 19px;
}

.group_4 {
  padding: 37px 0 10px;
}

.text_2 {
  color: #000;
  font-size: 28px;

  line-height: 48px;
}

.text_3 {
  color: #007aff;
  font-size: 28px;

  line-height: 48px;
}

.image_8 {
  width: 36px;
  height: 36px;
}

.text_4 {
  margin-left: 225px;
  color: #007aff;
  font-size: 30px;

  line-height: 29px;
}

.text_5 {
  margin-right: 219px;
  color: #000;
  font-size: 30px;

  line-height: 29px;
}

.section_5 {
  margin-left: 225px;
  align-self: flex-start;
  background-color: #007bff;
  width: 115px;
  height: 2px;
}

.section_6 {
  margin-right: 224px;
  align-self: flex-end;
  background-color: #000000;
  width: 115px;
  height: 2px;
}

.space-x-10>*:not(:first-child) {
  margin-left: 10px;
}

.space-y-33>*:not(:first-child) {
  margin-top: 33px;
}
</style>