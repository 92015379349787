<template>
  <div>
    <Wapheader />
    <div class="flex-col page ilike">
      <div class="flex-col">
        <div class="section_1">
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171821893161420.png"
            class="image" />
        </div>
        <div class="flex-col items-center section_2">
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171821891197418.png"
            class="image" />
          <div data-aos="fade-up" class="flex-col group_41">
            <div class="group_1">
              <span class="text">进化新生</span>
              <span class="text_1">复古机车魅力</span>
            </div>
            <div class="flex-col items-start group_2 view">
              <span class="text_2">64.2KM/h</span>
              <span class="text_3 text_4">极速（法规标准）</span>
            </div>
            <div class="flex-col items-start group_3">
              <span class="text_5">3KW</span>
              <span class="text_6">最大功率</span>
            </div>
            <div class="flex-col items-start group_2 view_1">
              <span class="text_7">60KM</span>
              <span class="text_3 text_8">续航里程*（等速）</span>
            </div>
            <div class="flex-col items-start group_4">
              <span class="text_9">48V35Ah</span>
              <span class="text_10">电池容量</span>
            </div>
            <div class="flex-col items-start group_2 view_2">
              <span class="text_11">三星21700</span>
              <span class="text_3 text_12">汽车级电芯</span>
            </div>
            <div class="flex-row group_5">
              <span class="text_13">*</span>
              <div class="group_6">
                <span class="text_14">数据来自常州光阳研发中心实验室，</br></span>
                <span class="text_15">实际使用中可能因载重、气温、温度等</br></span>
                <span class="text_16">客观条件的变化而有所差异。</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col items-center group_47 section_5s">

          <div data-aos="fade-up" class="swiper swiper1">
            <div class="swiper-wrapper">
              <div class="items-center justify-center swiper-slide">
                <img class="image_32" src="@/assets/images/ilike/01.png" alt="">
              </div>
              <div class="items-center justify-center swiper-slide">
                <img class="image_32"  src="@/assets/images/ilike/02.png" alt="">
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="flex-col items-center section_5">
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171831463663845.png"
            class="image" />
          <div data-aos="fade-up" class="flex-col group_44">
            <span class="text_26">颜值卖点</br></span>
            <div class="group_13">
              <span class="text_27">
                整车汲取了复古摩托车的动态线条，运用汽车设计语言，前面板来自概念汽车设计理念型面生动、更具科技感。</br></br>
              </span>
              <span class="text_28">i-Like整体造型演绎出摩登复古、未来科技的全</span>
              <span class="text_29">新格调，属于行业首创自成一派。</span>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="flex-col section_6">
          <div class="flex-col view_9">
            <div class="group_14">
              <span class="text_30">藏在细节里的</br></span>
              <span class="text_31">诗意魅力</span>
            </div>

            <div data-aos="fade-up" class="swiper swiper2" style="width: 100%;padding-bottom: 5rem;">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img src="@/assets/images/ilike/swiper/01.png" class="image_8" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/images/ilike/swiper/02.png" class="image_8" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/images/ilike/swiper/03.png" class="image_8" />
                </div>
              </div>
              <div style="bottom: 0;" class="swiper-pagination"></div>
            </div>
          </div>
        </div>


        <div class="flex-col items-center section_7">
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171834156618679.png"
            class="image_13" />
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171834500389060.png"
            class="image_14" />
        </div>
        <div class="flex-col section_8">
          <div class="flex-col items-center">
            <div data-aos="fade-up" class="flex-col group_17">
              <div class="group_18">
                <span class="text_32">“大材小用”</br></span>
                <span class="text_33">环抱式车架结构</span>
              </div>
              <div class="group_19">
                <span class="text_34">KYMCO大排量踏板摩托车</br></span>
                <span class="text_35">同材铬钼钢管</span>
              </div>
            </div>
            <img data-aos="fade-up"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171835688620799.png"
              class="image_15" />
          </div>
          <div class="flex-col items-start group_20">
            <img data-aos="fade-up"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745597291237988.png"
              class="image_16" />
            <img data-aos="fade-up"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745597560308442.png"
              class="image_17" />
          </div>
          <div class="flex-col items-end section_9">
            <div data-aos="fade-up" class="group_21">
              <span class="text_36">摩托车液压减震</span>
              <span class="text_37">轻量化铝合金锻造平叉</span>
            </div>
          </div>
        </div>
        <div class="flex-col items-center section_10">
          <span data-aos="fade-up" class="text_38">汽车级环保水性漆</span>
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171836914164638.png"
            class="image_18" />
        </div>
        <div class="flex-col items-center section_11">
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171837919313553.png"
            class="image" />
          <div class="flex-col group_47">
            <div data-aos="fade-up" class="justify-center">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171839546440919.png"
                class="image_19" />
            </div>

            <div class="flex-col items-center group_22">
              <img data-aos="fade-up"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171839564313444.png"
                class="image_20" />
              <div class="group_23">
                <span data-aos="fade-up" class="text_39">12项车规级动力电池技术<br></span>
                <span data-aos="fade-up" class="text_40">-20~60℃全天候满足电动摩托车<br></span>
                <span data-aos="fade-up" class="text_41">高能量密度、高放电倍率骑行要求<br></span>
                <span data-aos="fade-up" class="text_42">Ionex掌握核心BMS技术<br></span>
                <span data-aos="fade-up" class="text_43">为高端电动车而生！</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col group_24">
        <span data-aos="fade-up" class="text_44">经典色彩 复古魅力</span>
        <div class="flex-col group_25">
          <div class="flex-col items-center group_26">
            <img data-aos="fade-up"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16583745598916451685.png"
              class="image_21" />
            <img data-aos="fade-up" :src="require(`@/assets/images/colorsilike/${index}.png`)" class="image_22" />
          </div>
          <div data-aos="fade-up" class="items-center justify-center group_27">
            <span class="text_45">经典复古电摩</span>
            <span class="text_46">i-Like</span>
          </div>

          <div class="justify-center">
            <span v-show="index == 1" data-aos="fade-up" class="text_47">冰川蓝</span>
            <span v-show="index == 2" style="color: #bcbca1;" data-aos="fade-up" class="text_47">奶茶白</span>

          </div>
          <div class="justify-center group_28">
            <img data-aos="fade-up" @click="index = 1"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171821887714423.png"
              class="image_23" />
            <img data-aos="fade-up" @click="index = 2"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171821894358713.png"
              class="image_23 image_31" />
          </div>
          <div data-aos="fade-up" class="group_29">
            <span class="text_48">经典新生，复古格调</span>
            <span class="text_49">诗意浪漫随心而动</span>
          </div>
          <div data-aos="fade-up" class="flex-col items-center button"><span class="text_50">我要试驾</span></div>
        </div>
      </div>
      <img data-aos="fade-up"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171821891642866.png"
        class="image_26" />

    </div>
    <Wapfooter />
  </div>
</template>
<script>
import Wapheader from '../../components/wapheader/wapheader.vue';
import Wapfooter from '../../components/wapfooter/wapfooter.vue';
import Aos from "aos";
export default {
  name: 'Vue3Ilike',
  components: { Wapheader, Wapfooter },
  data() {
    return {
      index: 1
    };
  },

  async mounted() {
    Aos.init({
      offset: 120,
      duration: 500,
      delay: 500,
      once: true,
    })
    await this.$nextTick(() => {
      new Swiper('.swiper1', {
        autoplay: true,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
      )
      new Swiper('.swiper2', {
        autoplay: true,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }

      )
    });
  },

  methods: {

  },
};
</script>
<style lang="scss">
.ilike .swiper-pagination .swiper-pagination-bullet {
  background: #fff !important;
}
</style>
<style scoped>
.section_5s {
  padding: 0;
  background-image: url(../../assets/images/ilike/bg.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

.image {
  width: 100vw;
  height: 177.8667vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.equal-division-item {
  margin-left: 22.5px;
  flex: 1 1 235px;
  padding: 9px 0;
}

.text_3 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 27px;
}

.text_51 {
  margin-top: 18px;
  color: #313131;
  font-size: 28px;
  
  line-height: 27px;
}

.page {

  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.text_44 {
  align-self: center;
  color: #000;
  font-size: 42px;
  
  line-height: 41px;
}

.equal-division {
  padding: 0 21.5px;
}

.text_38 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 45px;
}

.text_47 {

  margin-top: 36px;
  align-self: flex-end;
  color: #67cfdc;
  font-size: 28px;
  
  line-height: 28px;
}

.button {
  margin-top: 48px;
  padding: 26px 0;
  align-self: center;
  border-radius: 46px;
  width: 332px;
  border: solid 4px #0b0306;
}

.text_55 {
  color: #313131;
  font-size: 35px;
  
  line-height: 33px;
}

.text_56 {
  color: #313131;
  font-size: 35px;
  
  line-height: 33px;
}

.text_57 {
  color: #313131;
  font-size: 28px;
  
  line-height: 33px;
}

.text_58 {
  color: #313131;
  font-size: 35px;
  
  line-height: 33px;
}

.text_59 {
  color: #313131;
  font-size: 35px;
  
  line-height: 33px;
}

.text_60 {
  color: #313131;
  font-size: 28px;
  
  line-height: 33px;
}

.text_61 {
  color: #313131;
  font-size: 35px;
  
  line-height: 33px;
}

.text_62 {
  color: #313131;
  font-size: 35px;
  
  line-height: 33px;
}

.text_63 {
  color: #313131;
  font-size: 28px;
  
  line-height: 33px;
}

.text_64 {
  color: #313131;
  font-size: 35px;
  
  line-height: 33px;
}

.text_65 {
  color: #313131;
  font-size: 35px;
  
  line-height: 33px;
}

.text_66 {
  color: #313131;
  font-size: 28px;
  
  line-height: 33px;
}

.view {
  margin-top: 140.5px;
}

.view_1 {
  margin-top: 61px;
}

.view_2 {
  margin-top: 59px;
}

.text_26 {
  align-self: flex-start;
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 45px;
}

.text_45 {
  color: #000;
  font-size: 32px;
  
  line-height: 30px;
  font-weight: bold;
}

.text_46 {
  color: #000;
  font-size: 32px;
  
  line-height: 30px;
  font-weight: bold;
}

.text_48 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}

.text_49 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}

.text_50 {
  color: #000;
  font-size: 32px;
  
  line-height: 32px;
}

.text_67 {
  color: #313131;
  font-size: 28px;
  
  line-height: 55px;
}

.text_68 {
  color: #313131;
  font-size: 28px;
  
  line-height: 55px;
}

.text {
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 59px;
}

.text_1 {
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 59px;
}

.text_2 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 38px;
}

.text_4 {
  margin-top: 14px;
}

.text_5 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 35px;
}

.text_6 {
  margin-top: 17px;
  color: #ffffff;
  font-size: 28px;
  
  line-height: 26px;
}

.text_7 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 35px;
}

.text_8 {
  margin-top: 16px;
}

.text_9 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 37px;
}

.text_10 {
  margin-left: 4px;
  margin-top: 15px;
  color: #ffffff;
  font-size: 28px;
  
  line-height: 26px;
}

.text_11 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 41px;
}

.text_12 {
  margin-top: 12px;
}

.text_13 {
  margin-top: 4px;
  color: #ffffff;
  font-size: 24px;
  
  line-height: 10px;
}

.text_20 {
  margin-left: 6px;
  margin-top: 33.5px;
  color: #ffffff;
  font-size: 28px;
  
  line-height: 27px;
}

.text_21 {
  margin-left: 3px;
  align-self: flex-start;
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 46px;
}

.text_27 {
  color: #ffffff;
  font-size: 28px;
  
  text-align: justify;
}

.text_28 {
  display:block;
  color: #ffffff;
  font-size: 28px;
  text-align: justify;
}

.text_29 {
  color: #ffffff;
  font-size: 28px;
  
  text-align: justify;
}

.text_30 {
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 59px;
}

.text_31 {
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 59px;
}

.text_36 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 62px;
}

.text_37 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 62px;
}

.text_14 {
  color: #ffffff;
  font-size: 24px;
  
  line-height: 30px;
}

.text_15 {
  color: #ffffff;
  font-size: 24px;
  
  line-height: 30px;
}

.text_16 {
  color: #ffffff;
  font-size: 24px;
  
  line-height: 30px;
}

.text_17 {
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 59px;
}

.text_18 {
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 59px;
}

.text_19 {
  color: #ffffff;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 59px;
}

.text_22 {
  margin-top: 181.5px;
  color: #00fff6;
  font-size: 24px;
  
  line-height: 23px;
}

.text_32 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 60px;
}

.text_33 {
  color: #ffffff;
  font-size: 48px;
  
  line-height: 60px;
}

.text_34 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 36px;
 
}

.text_35 {
 
  color: #ffffff;
  font-size: 28px;
  
  line-height: 36px;
}

.text_39 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 45px;
}

.text_40 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 45px;
}

.text_41 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 45px;
}

.text_42 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 45px;
}

.text_43 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 45px;
}

.text_23 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 41px;
}

.text_24 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 41px;
}

.text_25 {
  color: #ffffff;
  font-size: 28px;
  
  line-height: 41px;
}

.group_24 {
  margin-top: 100px;
  padding: 0 41px;
}

.image_26 {
  margin-top: 112px;
  width: 100vw;
  height: 381.7333vw;
}

.section_12 {
  margin-top: 51px;
  padding: 55px 0 104.5px;
  background-color: #ffffff;
}

.section_1 {
  height: 1334px;
  position: relative;
}

.section_2 {
  padding: 124.5px 0 83px;
  height: 1334px;
  position: relative;
}

.section_5 {
  padding: 109px 0 919.5px;
  height: 1334px;
  position: relative;
}

.section_6 {
  padding: 144.5px 0 53px;
  background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171832468719005.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

.section_7 {
  padding: 49px 0 64px;
  background-color: #3dd7ba;
}

.section_8 {
  padding: 143px 0 17px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171835361490592.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section_10 {
  padding: 90px 0 254px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171836811970813.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section_11 {
  padding: 90px 0 143.5px;
  height: 1334px;
  position: relative;
}

.group_25 {
  margin-right: 35px;
  margin-top: 55px;
}

.divider {
  background-color: #d2d2d2;
  height: 1px;
}

.view_3 {
  margin: 54px 29px 0 30px;
}

.group_33 {
  margin-left: 79px;
  margin-top: 49px;
  align-self: flex-start;
  line-height: 33px;
}

.view_4 {
  margin: 47.5px 30px 0;
}

.group_34 {
  margin-left: 79px;
  margin-top: 48px;
  align-self: flex-start;
  line-height: 33px;
}

.view_5 {
  margin: 47.5px 30px 0;
}

.group_35 {
  margin-left: 81px;
  margin-top: 49px;
  align-self: flex-start;
  line-height: 33px;
}

.view_6 {
  margin: 48.5px 30px 0;
}

.group_36 {
  margin-left: 81px;
  margin-top: 49px;
  align-self: flex-start;
  line-height: 33px;
}

.group_37 {
  margin: 48.5px 30px 0;
  padding-top: 31.5px;
  height: 141.5px;
  border-top: solid 1px #d2d2d2;
}

.group_41 {
  width: 590px;
  position: relative;
}

.image_32 {
  width: 100vw;
 

}

.section_4 {
  padding-left: 77px;
  padding-top: 103.5px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171821895464981.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.group_44 {
  width: 603px;
  position: relative;
}

.view_9 {
  margin: 0 30px;
}

.image_13 {
  width: 620px;
  height: 670px;
}

.image_14 {
  margin-top: 60px;
  width: 620px;
  height: 491px;
}

.group_20 {
  margin-top: 100px;
  padding: 0 65px;
}

.section_9 {
  margin-top: 65px;
  padding: 75px 0 763px;
  align-self: flex-start;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588171835942228117.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 662px;
}

.image_18 {
  margin-top: 52px;
  width: 589px;
  height: 893px;
}

.group_26 {
  padding-top: 398px;
  position: relative;
}

.group_27 {
  margin-top: 18px;
  padding: 0 142px;
}

.group_28 {
  margin-top: 40px;
}

.group_29 {
  margin-top: 43px;
  align-self: center;
  line-height: 36px;
  text-align: center;
}

.group_30 {
  margin-left: 0;
}

.group_38 {
  text-align: center;
  width: 552px;
}

.group_1 {
  align-self: flex-start;
  width: 289px;
}

.group_2 {
  padding: 0 43px;
}

.group_3 {
  margin-top: 60px;
  padding: 0 44px;
}

.group_4 {
  margin-top: 59px;
  padding: 0 42px;
}

.group_5 {
  margin-top: 146px;
  align-self: flex-start;
}

.group_7 {
  align-self: flex-start;
  width: 511px;
}

.image_2 {
  margin-top: 54px;
  align-self: center;
  width: 465px;
  height: 218px;
}

.group_47 {
  position: relative;
}

.group_9 {
  margin-top: 87px;
  padding-left: 3px;
}

.group_13 {
  margin-top: 35.5px;
  text-align: justify;
}

.group_14 {
  margin-left: 51px;
  align-self: flex-start;
  margin-bottom: 30px;
}

.image_8 {

  width: 100%;
}

.group_15 {
  margin-top: 192px;
}

.group_17 {
  width: 337px;
}

.image_15 {
  margin-top: 25px;
  width: 100vw;
  height: 56.1333vw;
}

.image_16 {
  width: 620px;
  height: 159px;
}

.image_17 {
  margin-top: 44px;
  width: 467px;
  height: 420px;
}

.group_21 {
  margin-left: 136px;
  margin-right: 40px;
  text-align: center;
  width: 486px;
}

.image_19 {
  width: 518px;
  height: 119px;
}

.group_22 {
  margin: 642px 8px 0 7px;
}

.image_21 {
  width: 633px;
  height: 166px;
}

.image_22 {
  width: 526px;

  position: absolute;
  right: 34px;
  top: 0;
}

.image_23 {
  width: 51px;
  height: 51px;
}

.image_31 {
  margin-left: 44px;
}

.image_27 {
  width: 128px;
  height: 128px;
}

.group_6 {
  margin-left: 7px;
  line-height: 30px;
  height: 90px;
}

.group_10 {
  margin-top: 31.5px;
  padding-bottom: 353px;
  height: 557.5px;
  position: relative;
}

.image_30 {
  margin-left: 20px;
  width: 25px;
  height: 25px;
}

.image_33 {
  margin-left: 0;
}

.group_18 {
  text-align: center;
}

.group_19 {
  margin-left: 6px;
  margin-top: 21px;
  line-height: 36px;
  text-align: center;
}

.image_20 {
  width: 503px;
  height: 67px;
}

.group_23 {
  margin-top: 46.5px;
  text-align: center;
}

.group_11 {
  margin-top: -204.5px;
  width: 395px;
  position: relative;
}

.group_12 {
  width: 541px;
  height: 472px;
  position: absolute;
  right: 0;
  top: 85.5px;
}

.image_3 {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 108px;
  bottom: 32px;
}

.image_4 {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 153px;
  bottom: 32px;
}

.image_5 {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 198px;
  bottom: 32px;
}

.image_6 {
  width: 461px;
  height: 470px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.image_7 {
  width: 541px;
  height: 119px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>